import React from "react";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import portoStyles from "@cssmodules/portfolio.module.scss";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Portfolio() {
    const images = useStaticQuery(graphql`
        query portofolioQuery {
            allFile(
                filter: { absolutePath: { regex: "/(portofolio)/" } }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);

    const [activePages, setActivePages] = React.useState(0);
    const renderPorto = () => {
        const firstIndex = activePages * 12;

        let porto = [];
        if (images?.allFile.edges.length > 0) {
            porto = [...images?.allFile.edges].slice(
                firstIndex,
                firstIndex + 12
            );
        }

        const view = porto.map(({ node }) => (
            <Col className={portoStyles.col} key={node.id} xs={6} md={6} lg={3}>
                <div className={portoStyles.card}>
                    <Img
                        className="h-100"
                        fluid={node.childImageSharp.fluid}
                        alt="rompi"
                    />
                </div>
            </Col>
        ));

        return view;
    };

    const handlePageClick = data => {
        setActivePages(data.selected);
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <main className={`container ${portoStyles.container}`}>
                <SEO title="Portfolio" />
                {/* <h2>
                    Berikut Beberapa Portofolio <b>{props.pageContext.title}</b>
                </h2> */}
                <Row>{renderPorto()}</Row>
                <ReactPaginate
                    previousLabel={null}
                    nextLabel={null}
                    breakLabel={"..."}
                    breakClassName={portoStyles.break}
                    pageCount={Math.ceil(images?.allFile.edges.length / 12)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={portoStyles.pagination}
                    pageClassName={portoStyles.paginationSub}
                    activeClassName={portoStyles.active}
                />
            </main>
        </Layout>
    );
}
